.dropdown {
  position: relative;

  &:not(.dropdown--up) {
    .dropdown__options {
      top: calc(100% + 1px);
      border-top-color: darken($dropdown-options-border-color, 5%);
    }
  }
}

.dropdown--up {
  .dropdown__options {
    bottom: 100%;
    border-bottom-color: darken($dropdown-options-border-color, 5%);
  }
}

.dropdown--right {
  .dropdown__options {
    right: 0;
    left: auto;
  }
}

.dropdown--center {
  .dropdown__options {
    left: 50%;
    transform: translateX(-50%);
  }
}


.dropdown--active {
  .dropdown__options {
    display: block;
  }
}

.dropdown--caret {
  .dropdown__toggle {
    padding-right: 30px;
    background: $select-caret no-repeat right 10px center;
  }
}

.dropdown__toggle {
  cursor: pointer;
  white-space: nowrap;
  @include user-select(none);
}

.dropdown__options {
  margin-top: -1px;
  padding: 5px;
  min-width: 150px;
  z-index: 2;
  position: absolute;
  left: 0;
  background-color: $dropdown-options-bg;
  border: 1px solid $dropdown-options-border-color;
  font-size: $dropdown-item-font-size;
  display: none;
  border-radius: $border-radius;
  @include user-select(none);
}

.dropdown__item {
  padding: 6px 8px;
  border-radius: $border-radius;
  cursor: pointer;
  color: $dropdown-item-color;
  white-space: nowrap;
  display: block;
  width: 100%;

  &:hover {
    background-color: $dropdown-item-hover-bg;
    color: inherit;
  }

  & > img {
    vertical-align: top;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }
}

.dropdown-max-height {
  max-height: calc(100vh - 65px);
}
