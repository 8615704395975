.bg-plan-standard { background-color: #3cbdd3 }
.bg-plan-enterprise { background-color: #174651 }
.bg-plan-enterprise-lite { background-color: #3485B7 }
.bg-plan-enterprise-plus { background-color: #4C1772 }
.bg-plan-poc { background-color: #71996a }
.bg-plan-enterprise-lite-trial { background-color: #6eb9ec }
.bg-plan-enterprise-plus-trial { background-color: #a084e4 }

.support-tab-content{
  .issue-list{
    padding-top: 0;
  }
}
