// Base
.main__left {
  background: $sidebar-bg;
  height: 100%;
  position: relative;
  flex: 0 0 auto;
  width: $sidebar-width;
  overflow: auto;
  display: flex;
  flex-direction: column;


  @include media-breakpoint-down(lg) {
    position: fixed;
    left: 0;
    top: 0;
    transform: translate3d(-$sidebar-width, 0, 0);
    opacity: 0;
    transition: opacity 250ms, transform 250ms;
    z-index: $sidebar-z-index;

    &.main__left--toggled {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @include media-breakpoint-up(xl) {
    &.mini {
      width: 60px;
      padding: 6px;

      a.mini {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav__icon {
          margin-right: 0;
        }

        span:nth-child(2) {
          display: none;
        }

        &.mini {
          .menu-badge {
            position: absolute;
            height: 8px;
            width: 8px;
            top: -1px;
            left: 32px;
            background: red;

            .menu-badge-count{
              display: none;
            }
          }
        }
      }

      .nav-footer{
        width: 71px;

        button{
          margin-left: 11px;
        }
      }
    }
  }

  .nav-footer{
    max-width:250px;
    overflow: hidden;
  }
}

// Logo
.logo {
  height: $header-height;
  display: flex;
  align-items: center;
}

.logo__img {
  width: $logo-img-width;
  margin-bottom: -5px;
}

// Close
.sidebar-close {
  @include size(30px, 30px);
  @include make-center();
  border-radius: $border-radius;
  cursor: pointer;
  margin: 0 12px 0 -5px;

  svg {
    fill: $white;
    @include size(17px, 11px);
  }

  &:hover {
    background-color: rgba($white, 0.1);
  }
}

// Backdrop
.backdrop--sidebar {
  z-index: $sidebar-z-index - 1;
}

// Nav
.nav__icon {
  @include size(40px, 40px);
  @include make-center();
  border-radius: 50%;
  border: 2px solid #081c42;
  background-color: #072549;

  & > img {
    max-width: 18px;
    max-height: 18px;
  }
}

.nav__item--active {
  .nav__title {
    text-shadow: 0.5px 0 0 currentColor;
  }
}

// Org Switch
.org-switcher__icon {
  fill: white;
}

.org-switch {
  width: 240px;
  background-color: $org-switch-bg-color;
  z-index: 1000;

  @include media-breakpoint-down(lg) {
    width: 220px;
  }

  .org-switch__item {
    padding-right: 3px;
    padding-bottom: 3px;
  }

  .org-switch__item + .org-switch__item {
    padding-top: 15px;
  }

  .org-switch__others {
    border-top: 1px solid #ffffff40;
  }

  .org-switch__icon {
    padding-bottom: 1px;
  }

  .org-switch__status {
    width: 8px;
    height: 8px;
    box-shadow: $org-status-shadow;
  }
}

.badge-count {
  background-color: #566278;
  height: 22px;
  border: 1px solid #566278;
  border-radius: 3px;
  padding: 1px 2px 1px 2px;
}