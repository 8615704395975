$autocomplete-font-size: 12px;
$autocomplete-max-height: 35px;
$autocomplete-container-width: 150px;
$autocomplete-indicator-width: 25px;

.dropdown {
  .autocomplete__inner__control {
    border-radius: $border-radius;
    min-height: 0;
    border: 1px solid $form-input-border-color;
    box-shadow: none;
    font-size: $autocomplete-font-size;
    color: $form-input-color;
    width: 100%;

    &:hover {
      border-color: $form-input-border-color;
    }

    &--is-focused, &--menu-is-open {
      border-color: $gray-400 !important;
      box-shadow:  0 0 0 4px $gray-100;

      &:hover {
        border-color: $gray-400;
      }
    }
  }

  .autocomplete__inner__value-container > div {
    :not(.autocomplete__inner__placeholder) {
      padding-bottom: 1px;
    }
  }

  .autocomplete__inner__placeholder {
    color: $body-color;
    padding-bottom: 1px;
  }

  .autocomplete__inner__indicators {
    @include size($autocomplete-indicator-width, 100%);
  }

  .autocomplete__inner__indicator {
    padding: 0;
    color:$form-input-border-color;
    &:hover{
      color:$form-input-color;
    }
    & > svg {
      width: 15px;
      height: 15px;
    }
  }

  .autocomplete__inner__indicator-separator {
    display: none;
  }

  .autocomplete__inner__menu {
    z-index: 2;
    margin: 0;
    padding: 0;
    border-radius: $border-radius;
    font-size: $autocomplete-font-size;
    box-shadow: none;
    border: 1px solid $gray-200;
  }

  .autocomplete__inner__option {
    &:active {
      background-color: $dropdown-item-active-bg;
    }
  }

  .autocomplete__inner__option--is-focused {
    background-color: $dropdown-item-hover-bg;

    &:active {
      background-color: $dropdown-item-active-bg;
    }
  }

  .autocomplete__inner__option--is-selected {
    background-color: $dropdown-item-selected-bg;

    &:active {
      background-color: $theme-blue-light;
    }
  }

  .autocomplete__inner__single-value {
    color: $form-input-color;
  }

  .form-field {

    .autocomplete__inner__placeholder{
    color: #8a8a8a;
    }

    &.autocomplete__container {
      all: unset;
    
      .autocomplete__inner__control {
        height: $form-input-height;
      } 
    }

    &.invalid {
      .autocomplete__inner__control {
        border-color: $red !important;
      } 
    }

    &.clearable {
      .autocomplete__inner__control{
       min-height: 42px;
       height: auto;
       min-width: 25px;
      }

      &.height-sm {
        .autocomplete__inner__control,  .autocomplete__inner__indicators {
          min-height: 33px;
        }
      }
      &.height-xs {
        .autocomplete__inner__control,  .autocomplete__inner__indicators {
          min-height: 28px;
        }
      }

      .autocomplete__inner__indicators {
        min-height: 42px;
        height: auto;
        margin-right: 10px;

        .autocomplete__inner__clear-indicator {
          cursor: pointer;
        }
      }
    }
  }

  &.add-on-left{
    width:87px;
    .autocomplete__container,
    .autocomplete__inner__control{
      width:87px;
    }
    .autocomplete__inner__control{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }


  &.answer-dropdown {
    &.answered {
      .autocomplete__inner__control {
        background: $green;
        color: $white;
        border: none;

        svg {
          fill: $white;
        }
      }

      .autocomplete__inner__single-value {
        color: $white;
      }
    }

    &.un-answered {
      .autocomplete__inner__control {
        background: $orange;
        border: none;
        color: $white;

        svg {
          fill: $white;
        }
      }

      .autocomplete__inner__single-value {
        color: $white;
      }
    }
  }

  &.no-borders{

    .autocomplete__inner__control{
      border: none;
      font-size: 13px;
      font-weight: 500;


    }
    .autocomplete__inner__indicators{
      display: none;
    }

  }
}

.eng-expertise-level-menu{

  .autocomplete__inner__menu-list{
    max-height:365px ;
    padding-bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #ffffff;
    }

    &::-webkit-scrollbar
    {
      width: 6px;
      height: 6px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #000000;
    }
  }

  .autocomplete__inner__option--is-selected {
    background-color: $messages-item-hover-bg;
    color: $black;
    cursor: not-allowed;

    &:active {
      background-color: $messages-item-hover-bg;
      color: $black;
      cursor: pointer;
    }
  }
}


.issue-category-level-menu{

  .autocomplete__inner__indicators {
    width: 32px;
  }

  .autocomplete__inner__menu {
    margin-left: -100px;
    margin-top: 8px;
    width:220px;
  }


  .autocomplete__inner__option--is-selected {
    background-color: $messages-item-hover-bg;
    color: $black;
    cursor: not-allowed;

    &:active {
      background-color: $messages-item-hover-bg;
      color: $black;
      cursor: pointer;
    }
  }

  .autocomplete__inner__menu-list{
    max-height: 420px;
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #ffffff;
    }

    &::-webkit-scrollbar
    {
      width: 6px;
      height: 6px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #000000;
    }
  }

  .autocomplete__inner__control{
    height: 28px;
  }

}


.dropdown .eng-expertise-level-menu
{
  .autocomplete__inner__value-container > div :not(.autocomplete__inner__placeholder) {
    padding: 6px
  }
}

.dropdown .issue-category-level-menu,
{
  .autocomplete__inner__value-container > div :not(.autocomplete__inner__placeholder) {
    padding: 4px ;
    font-size: 9px;
  }

  &.clearable.height-xs {
    .autocomplete__inner__indicators{
      margin-right: 0;
    }
    .autocomplete__inner__control {
      border: 1px solid #dedede;
    }
  }


  .autocomplete__inner__value-container{
    padding: 0;
    .autocomplete__inner__input::placeholder{
      margin-left: 5px;
    }
  }
}

.dropdown .issue-category-filter{
  .issue-filter-options{
    padding: 3px;
    margin: 0;
    span{
      padding: 3px 5px;
    }
  }
}


.dropdown.issue-tag-filter {
  .autocomplete__inner__value-container .autocomplete__inner__multi-value__label {
    padding-bottom: 3px;
  }

  .autocomplete__inner__multi-value__remove {
    padding-top: 3px;
    color: $gray-400;
  }
}