button {
  &:focus {
    outline: none !important;
  }
}

.button {
  border-width: 1px;
  border-style: solid;
  transition: background-color 300ms, opacity 300ms, color 300ms, border-color 300ms;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  font-weight: $font-weight-medium;
  color: $white;
  outline: none !important;
  line-height: 100%;
  border-radius: $border-radius;
  

  &:not(.button--sm) {
    height: $button-height;
  }

  &:not(.button--sm):not([class*="px-"]) {
    padding: 0 1.75rem;
  }

  &:disabled,
  &.disabled {
    cursor: default;
  }

  & > span {
    transition: opacity 250ms;
  }
}

.button--sm {
  height: $button-sm-height;
  padding: 0 0.75rem;
  font-size: $font-size-xs;
}

a.button {
  display: inline-flex;
  align-items: center;
}

@each $variant, $color in $button-variants {
  .button--#{$variant} {
    @if $variant == secondary {
      &:not(:disabled) {
        border-color: $gray-500;
        color: $gray-500;

        &:hover {
          background-color: $gray-100;
        }
      }
    } @else if ($variant==light) {
      &:not(:disabled) {
        border-width: 1px;
        border-color: $gray-100;
        color: $gray-500;
        background: $gray-100;

        &:hover {
          border-width: 1px;
          border-color: $gray-500;
        }
      }
    } @else {
      &:not(:disabled) {
        background: $color;
        border-color: $color;

        &:hover {
          background-color: darken($color, 5%);
          border-color: darken($color, 5%);
        }
      }
    }

    &:disabled {
      background-color: $gray-100;
      color: $gray-500;
      border-color: $gray-100;
    }
  }
}

.button--load {
  position: relative;

  & > span {
    opacity: 0;
  }

  &:before {
    @include loader(15px, $button-load-track-bg, $button-load-point-bg);
    content: '';
  }
}
