.phone-input.react-tel-input {
  font-family: inherit;
  font-size: inherit;

  .selected-flag {
    border-radius: $border-radius 0 0 $border-radius;
    padding-left: 12px;
    width: 45px;
    z-index: 9;
    background: transparent !important;
  }

  input[type=text],
  input[type=tel] {
    &.invalid {
      border-color: $red !important;
    }

    border-radius: $border-radius;
    width: 100%;
    height: $form-input-height;

    &:focus {
      z-index: 1;
      border-color: $form-input-focus-border-color;
      box-shadow:  0 0 0 4px $gray-100;
    }
  }

  input[type=text],
  input[type=tel],
  .flag-dropdown {
    border-color: $form-input-border-color;
  }

  .flag-dropdown {
    background-color: transparent;
    transition: background-color 300ms;
    border-right: 0;
    border-radius: $border-radius 0 0 $border-radius;

    &.open-dropdown,
    &:hover {
      background: $gray-100;
    }
  }

  .country-list {
    box-shadow: none;
    margin: -1px 0 0 0;
    border: 1px solid $dropdown-options-border-color;
    font-size: $dropdown-item-font-size;
    padding: 10px 0;

    .country {
      padding: 10px 15px;

      &:hover {
        background-color: $dropdown-item-hover-bg;
      }

      &.highlight {
        background-color: $dropdown-item-active-bg;
      }
    }
  }
}
