// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);

// Colors
$black: #000;
$white: #fff;
$red: #C72C48;
$blue: #081C42;
$blue-sky: #65AAFF;
$green: #32c787;
$orange: #ff9800;
$gray-50: #fafafa;
$gray-100: #eaedee;
$gray-200: #dedede;
$gray-300: #d9d9d9;
$gray-400: #767676;
$gray-500: #3e3e3e;

// Theme
$theme-blue-dark: #081c42;
$theme-blue-light: #073052;
$theme-muted: #eee;
$theme-hover-bg: #fafafa;

// Fonts
$font-family: "Mark", sans-serif;
$font-family-mono: "Menlo", monospace;
$font-family-deja-vu-sans-book: "DejaVuSans", monospace;
$root-font-size: 14px;
$font-size-base: $root-font-size;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-normal: 400;
$font-weight-light: 300;
$font-weight-extra-light: 200;
$font-weight-thin: 100;
$line-height-base: 1.4;

$font-size-xs: 0.7142857142857143rem;
$font-size-sm: 0.8571428571428571rem;
$font-size-md: 0.929rem;
$font-size-lg: 1.1428571428571428rem;
$font-size-xl: 1.2857142857142858rem;

// Body
$body-color: $black;
$body-bg: $white;
$body-color-muted: #767676;

// Links
$link-color: $body-color;

// Headings
$headings-color: $black;

// Border Radius
$border-radius: 3px;
$border-radius-lg: 5px;

// Layout
$container-width: 1200px;
$container-md-width: 1000px;

// Sidebar
$sidebar-width: 250px;
$sidebar-bg: transparent linear-gradient(90deg, #073052 0%, #081c42 100%) 0% 0%
  no-repeat padding-box;
$sidebar-z-index: 12;
$org-switch-bg-color: #53627a;
$org-status-shadow: 0 1px 2px rgba($black, 0.25);

// Form
$form-input-bg: $white;
$form-input-height: 42px;
$form-input-border-color: $gray-400;
$form-input-focus-border-color: $gray-500;
$form-input-color: $black;
$select-caret: url(../../img/icons/caret.svg);
$placeholder-color: $gray-400;
$form-input-readonly-border-color: #dedede;

// Header
$header-bg: $white;
$header-height: 75px;
$header-z-index: $sidebar-z-index - 2;
$op-popup-border-color: #dedede;

// Logo
$logo-img-width: 85px;

// Content
$content-inner-bg: $white;

// Search
$search-width: 350px;
$search-main-width: 490px;

// Buttons
// FIXME: Fix button color variants
$button-variants: (
  primary: $blue,
  secondary: $white,
  danger: $red,
  success: $green,
  warning: $orange,
  light:$gray-100
);

$button-height: 38px;
$button-sm-height: 26px;
$button-default-border-color: #e6e6e6;
$button-load-track-bg: #000;
$button-load-point-bg: #dcd7d7;

// List
$list-item-border-color: #f3f3f3;

// Messages
$messages-item-hover-bg: $theme-hover-bg;
$messages-item-eng-bg: #ebf3ff;
$messages-item-eng-hover-bg: #d1e4ff;
$messages-item-hightlight-bg: #fff5b4;
$messages-date-font-size: $font-size-xs;
$messages-date-border-color: $list-item-border-color;
$messages-avatar-size: 40px;
$messages-attachment-border-color: $messages-date-border-color;
$messages-system-bg-color: #f9f9f9;
$messages-inline-code-border-color: #dcdcdc;
$messages-inline-code-color: #e3116c;
$messages-quote-border-color: #8c8c8c;
$messages-quote-text-color: #515151;

// Messages reply
$reply-base-height: 50px;

// Dropdown
$dropdown-item-color: $black;
$dropdown-item-font-size: $font-size-base - 1;
$dropdown-item-hover-bg: $gray-50;
$dropdown-options-bg: $white;
$dropdown-options-border-color: #ececec;
$dropdown-item-active-bg: #f2f2f2;
$dropdown-item-selected-bg: #081c42;
$dropdown-options-shadow: 0 3px 5px rgba($black, 0.05);
$dropdown-auto-suggest-bg:#E0E0DE;

// Modal
$modal-z-index: 1000;
$modal-bg: rgba($black, 0.1);
$modal-width: 600px;
$modal-sm-width: 300px;
$modal-md-width: 450px;
$modal-lg-width: 800px;
$modal-category-width: 580px;
$modal-title-font-size: $font-size-base;
$modal-close-hover-bg: #eee;
$modal-close-icon: url(../../img/icons/close.svg);

// Alert
$alert-z-index: 10000;
$alert-danger-bg: #c72c48;
$alert-success-bg: #00cb86;
$alert-info-bg: $blue-sky;
$alert-height: 75px;
$alert-close-icon: url(../../img/icons/close-light.svg) no-repeat center;
// Uploads
$upload-z-index: $modal-z-index - 3;
$upload-bottom-z-index: $upload-z-index + 1;
$upload-top-z-index: $upload-z-index + 2;

// Login
$login-bg: $white;
$login-right-bg: linear-gradient(
  120deg,
  $theme-blue-dark 0%,
  $theme-blue-light 100%
);

// Updater
$updater-bg-color: #f2f2f2;

// IE Warning
$ie-font-family: $font-family, Arial, Helvetica, sans-serif;
$ie-z-index: 999999;
$ie-bg: $white;
$ie-browser-color: $body-color;
$ie-browser-hover-bg: #f6f6f6;
$ie-skip-color: $body-color-muted;

// Panic
$panic-bg: #252323;
$panic-theme: #c72e49;

// Signup
$signup-summary-bg: #f9fafb;
$signup-steps-line-bg: #eee;
$signup-aside-bg: #072448;
$signup-aside-width: 85px;
$signup-container-width: 1200px;
$signup-progress-bg: #e6e9ec;
$signup-progress-active-bg: #072448;
$signup-steps-complete-bg: #8a8a8a;
$signup-steps-complete-color: rgba($black, 0.5);

// System alert
$system-alert-z-index: 100