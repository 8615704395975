/* Tab Style Default */
.tab-strips-container{
  .tab-strip-wrapper{
    &.selected{
      border-bottom-width: 3px;
      border-bottom-color: $gray-500;
      &:hover{
        border-bottom: 3px solid $gray-500;
      }
    }
    &:hover{
      background: $gray-100;
      border-bottom: 3px solid $gray-100;
    }
  }
}

/* Scrollable Tab Style */

//Library Styles
.react-horizontal-scrolling-menu--wrapper {
  /* chrome and chromium based */
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .react-horizontal-scrolling-menu--inner-wrapper {
    border-top-left-radius: 4px;
  }
}

//Customized Styles for SUBNET
.react-horizontal-scrolling-menu--wrapper{
  max-width: 100%;
  width: 100%;
}

.tab-items-scroll-container{
  flex: 1;
  gap: 2px;

  .tab-item{
    border-bottom: 0;
    min-height: 34px;
    background: #FFFFFF;

    &:last-child{
      margin-right: 2px;
    }

    &:first-child{
      border-left-width: 0;
    }

    .tab-strip-wrapper{
      &:focus-visible{
        outline: 0;
      }
      .tab-strip{
        min-width: 165px;
      }
    }
  }

}

//Customized Styles for Pools view
.server-pool-tabs {
  .react-horizontal-scrolling-menu--scroll-container{
    border: 0;
  }
  .react-horizontal-scrolling-menu--wrapper,
  .react-horizontal-scrolling-menu--inner-wrapper
  {
    border:0.5px solid #CECECE;
  }
  .tab-items-scroll-container {
    .tab-item {
      border: 0;
      min-height: 40px;
      .tab-strip-wrapper {
        .tab-strip {
          margin:auto;
          min-width: 160px;
        }
      }
    }
  }

  .react-horizontal-scrolling-menu--arrow-right{
    div {
      border: 0;
    }
  }
}


.tab-close-context-menu{

  &.contextify{
  box-shadow:  rgba(0, 0, 0, 0.1) 4px 4px 4px 4px;
  }

  .contexify_item:not(.contexify_item-disabled):focus>.contexify_itemContent,
  .contexify_item:not(.contexify_item-disabled):hover>.contexify_itemContent{
    background: $theme-blue-light;
  }
  .contexify__item:not(.contexify__item--disabled):focus {
    background: $blue;
  }


}