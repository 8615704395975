.bg-health-code {
  background: $blue;
  color: $white;
}

.file-info-box {
  border: 1px dashed #c3c3c3e2;
}

.version-tooltip {
  background: #0000001f !important;
  border: 0.20000000298023224px solid #d6d6d6;
  width: 170px;
  height: 20px;
  padding: 1px;
  font-size: 10px;
  color: #1a1a1a;
  margin-top: 2px !important;
  text-align: center;
  &:after {
    display: none;
  }
}

.health-tab-content{
  flex: 1 1 auto;
}

.tooltip {
  &.used-space-not-available {
    text-align: left;
    line-height: 1rem;
  }

  &.health-overview-capacity {
    background: $white;
    color: $black;
    border-color: $white;
    opacity: 1;


    &.type-dark.place-right:after,
    &.type-dark.place-right:before{
      border-right-color: $white;
      background: $white;
    }
  }
}
