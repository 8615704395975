.search {
  transition: width 300ms;

  &:not(.search--main) {
    @include media-breakpoint-up(lg) {
      max-width: $search-width;
      width: 100%;
      min-width: $search-width - 100px;
      flex: 1;
    }
  }
}

.search--main {
  @include media-breakpoint-up(lg) {
    max-width: $search-main-width;
    width: 100%;
    margin: 0 1.5rem 0 1.5rem;
    &.search--expanded {
      width: 100%;
    }
  }
}

.search-select {
  .autocomplete__inner__control {
    border-color: $gray-200 !important;
    height: 2.5rem;

    &--menu-is-open {
      border-color: $gray-400 !important;
    }
  }

  .autocomplete__inner__option{
    overflow-wrap: break-word;
  }
}