.date-picker {
  &.react-datepicker {
    font-family: $font-family;
    font-size: $font-size-base;
    border: 0;
    display: flex;
    flex-direction: column;
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
  }

  .react-datepicker__month-container {
    float: none;
    width: 100%;
  }

  .react-datepicker__header {
    border: 0;
    background-color: transparent;
    padding: 0;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day-name, 
  .react-datepicker__day, 
  .react-datepicker__time-name {
    width: 35px;
    height: 35px;
    margin: 0;
    background: #fafbfb;
    color: #1f2750;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $white;
    font-size: $font-size-sm;
    padding: 10px;
    border-radius: $border-radius !important;
  }

  &.date-picker--alt {
    .react-datepicker__day-name, 
    .react-datepicker__day {
      margin: -1px -1px 0;
      width: 45px;
      font-size: $font-size-md;

      &.react-datepicker__day--selected {
        position: relative;
        
        &, &:hover {
          background-color: $white;
          color: $blue-sky;
        }

        &:before {
          content: "";
          width: 34px;
          height: 34px;
          position: absolute;
          inset: 0 0 0 0;
          margin: auto;
          border: 1px solid $blue-sky;
          border-radius: 50%;
        }
      }
    }

    .react-datepicker__day {
      border-color: $gray-100;
      border-radius: 0 !important;
      height: 45px;
      width: 45px;
    }

    .react-datepicker__current-month {
      margin-bottom: 20px;
    }
  }

  .react-datepicker__day, 
  .react-datepicker__month-text, 
  .react-datepicker__quarter-text {
    &:hover {
      background-color: $white;
    };

    &:not(.react-datepicker__day--disabled) {
      &:hover {
        background-color: $gray-50;
      };
    }

    &.react-datepicker__day--selected {
      &, &:hover {
        background-color: $blue-sky;
        color: $white;
      }
    }
  }

  .react-datepicker__current-month, 
  .react-datepicker-time__header, 
  .react-datepicker-year-header {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    margin: 7px 0 0 0;
  }

  .react-datepicker__day-names {
    margin-bottom: 1px;
    font-size: $font-size-sm;
  }

  .react-datepicker__day-name,
  .react-datepicker__day--outside-month {
    color: $body-color-muted;
  }

  .react-datepicker__day--disabled {
    color: $gray-200;
    position: relative;
  }

  .react-datepicker__navigation {
    width: 30px;
    height: 30px;
    border: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 7px 14px;
    border-radius: $border-radius;
    top: 0;
    
    &:hover {
      background-color: $gray-50;
    }

    &:focus-visible {
      background-color: $gray-100;
    }

    .react-datepicker__navigation-icon {
      display: none;
    }
  }

  .react-datepicker__navigation--previous {
    background-image: url(/img/icons/chevron-left-black.svg);
    left: 0;
  }

  .react-datepicker__navigation--next {
    background-image: url(/img/icons/chevron-right-black.svg);
    right: 0;
  } 

  .react-datepicker__day--today, 
  .react-datepicker__month-text--today, 
  .react-datepicker__quarter-text--today {
    font-weight: normal;
  }

  .react-datepicker__year-read-view--down-arrow, 
  .react-datepicker__month-read-view--down-arrow, 
  .react-datepicker__month-year-read-view--down-arrow {
    border: 0;

    &:before {
      display: none;
    }
  }

  .react-datepicker__year-read-view, 
  .react-datepicker__month-read-view, 
  .react-datepicker__month-year-read-view {
    border-color: $gray-100;
    border-radius: $border-radius;
    font-size: $font-size-sm;
    line-height: 100%;
    padding: 7px 20px 8px 10px;
    width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    background: $gray-50 url(/img/icons/caret-alt.svg) no-repeat right 8px top 11px;
    background-size: 8px;

    &:focus-visible,
    &:hover {
      background-color: darken($gray-50, 2%);
    }
  }

  .react-datepicker__today-button {
    border: 0;
    margin: 10px;
    border-radius: $border-radius;
    font-weight: normal;
    font-size: $font-size-sm;
    padding: 6px 0;
    background-color: $gray-50;
    border: 1px solid $gray-100;

    &:hover {
      background-color: darken($gray-50, 1%);
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .react-datepicker__year-dropdown, 
  .react-datepicker__month-dropdown, 
  .react-datepicker__month-year-dropdown {
    background-color: $dropdown-options-bg;
    border-color: $dropdown-options-border-color;
    border-radius: $border-radius;
    box-shadow: $dropdown-options-shadow;
    padding: 5px;
    text-align: left;
    left: 0;
    width: 165px;
    right: 0;
    top: 0;
    margin: 0 auto;
  }

  .react-datepicker__year-option, 
  .react-datepicker__month-option, 
  .react-datepicker__month-year-option {
    padding: 6px 8px;
    border-radius: $border-radius;
    cursor: pointer;
    color: $dropdown-item-color;
    font-size: $font-size-sm;
    line-height: 100%;

    &:hover {
      background-color: $dropdown-item-hover-bg;
    }
  }

  .react-datepicker__year-option--selected_year,
  .react-datepicker__month-option--selected_month {
    font-weight: $font-weight-medium;
    
    &, &:hover {
      background-color: $dropdown-item-selected-bg;
      color: $white;
    }
  }

  .react-datepicker__year-option--selected, 
  .react-datepicker__month-option--selected, 
  .react-datepicker__month-year-option--selected {
    display: none;
  }

  .react-datepicker__navigation--years-upcoming,
  .react-datepicker__navigation--years-previous {
    top: 0;
    width: 100%;
    background: url(/img/icons/caret-alt.svg) no-repeat center;
    height: 10px;
  }

  .react-datepicker__navigation--years-upcoming {
    transform: rotate(180deg);
  }
  
  .react-datepicker__month-dropdown-container {
    margin-bottom: 10px;
  }

  .react-datepicker__day--keyboard-selected{
    color: $black;
    background: $gray-100;
  }


  .react-datepicker-time__input-container {
    border: 1px solid #eaeaea;
  }


  .react-datepicker__day--in-range {
    color: $gray-100;
    background: $blue;

    &.react-datepicker__day--range-start,
    &.react-datepicker__day--range-end{
      background: #65AAFF;
    }

  }

}

.react-datepicker-popper {
  border: 1px solid $dropdown-options-border-color;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $dropdown-options-shadow;
  padding: 20px 10px 10px;

  &[data-placement^="top"],
  &[data-placement^="bottom"] {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .react-datepicker__current-month {
    display: none;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}