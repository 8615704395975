#main {
  display: flex;
  height: 100%;
}

.main__right {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
  min-width: 0;
}

.content {
  background-color: $content-inner-bg;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  z-index: $header-z-index - 1;
  position: relative;
}

.content__body {
  position: relative;
  flex: 1 1 100%;
  overflow: auto;

  &--scroll {
    overflow: auto;
  }

  &--center {
    padding: 15px;
    display: flex;
    align-items: center;
    overflow: auto;

    @include media-breakpoint-up(md) {
      justify-content: center;
    }
  }
}

.container {
  width: 100%;
  max-width: $container-width;

  &.container--md {
    max-width: $container-md-width;
  }
}


