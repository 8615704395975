.slim-scroll{
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #ffffff;
  }

  &::-webkit-scrollbar
  {
    width: 6px;
    height: 6px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb
  {
    background-color: #000000;
  }
}

.chat-sidebar-actions{
  height: calc(100% - 15px);
  left: calc(100% - 280px);
  width: 280px;

  @include media-breakpoint-between(lg,xl) {
    display: block;
  }

  @include media-breakpoint-between(xs,sm)  {
    display: none;
  }

  @include media-breakpoint-between(md,lg) {
    display: none;
    left: 54%;
    z-index: 15;
  }
}

.issue-call-info{
  z-index: 14;
  width: calc(100% - 200px);
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.issue-priority-info {
  z-index: 14;
  width: calc(100% - 200px);
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.issue-info-button {
  left: 68%;
  top: 40px;
  z-index: 16;
  @include media-breakpoint-down(sm) {
    left: 67%;
  }

}

.message-input-box{
  width: calc(100% - 200px);
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.messages {
  display: flex;
  flex-direction: column;
  height: 100%;

  width:calc(100% - 77px);

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.messages__body {
  height: 100%;
  overflow: auto;
  padding: 20px 0;
  position: relative;

  width:calc(100% - 205px);
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.messages__date {
  text-align: center;
  position: relative;
  margin: 15px 0 10px;

  &:before {
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 13px;
    background-color: $gray-100;
    left: 0;
  }
}

.messages__item {
  padding: 7px 25px 7px $messages-avatar-size + 40;
  position: relative;
}

.messages__item--group {
  margin-top: 10px;
}

.messages__item--engineer {
  background-color: $messages-item-eng-bg;

  &:hover {
    background-color: $messages-item-eng-hover-bg;
  }
}

.messages__item--system {
  padding-left: 0;
  padding-right: 0;
  background-color: $white;

  &:hover {
    background-color: $white;
  }
}

.messages__item--system,
.messages__item--attachment {
  padding: 7px 25px 7px $messages-avatar-size + 40;
}

.messages__item--highlight {
  animation: highlight 4s ease-out;
}

@keyframes highlight {
  from {
    background-color: $messages-item-hightlight-bg;
  }
  to {
    background-color: initial;
  }
}

.messages-content,
.messages__content {
  word-wrap: break-word;
  word-break: break-word;

  code {
    background-color: $white;
    border: 1px solid $messages-inline-code-border-color;
    border-radius: $border-radius;
    margin: 0;
    padding: 1px 4px 3px;
    font-size: 90%;
    font-family: inherit;
    color: $messages-inline-code-color;
    line-height: 1.95;
  }

  pre {
    border: 1px solid $messages-attachment-border-color;
    border-radius: $border-radius;
    font-size: $font-size-sm;
    background-color: $white;
    padding: 18px 20px;
    position: relative;
    overflow: auto;

    code {
      background-color: transparent;
      padding: 0;
      border: none;
      font-size: 100%;
      color: inherit;
    }

    &,
    & > code {
      white-space: pre-wrap;
    }
  }

  p {
    a {
      text-decoration: underline;

      &:focus-visible {
        color: $link-color;
      }
    }
  }

  p, ol, ul, pre {
    margin: 0 0 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    margin: 5px 0;
    padding: 5px 15px;
    color: $messages-quote-text-color;
    border-left: 4px solid $messages-quote-border-color;
    white-space: normal;

    &:nth-last-child(2) {
      margin: 5px 0 0;
    }
  }

  h1 {
    font-size: 2em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.17em;
    font-weight: bold;
  }

  h4 {
    font-size: 1em;
    font-weight: bold;
  }

  h5 {
    font-size: 0.83em;
    font-weight: bold;
  }

  h6 {
    font-size: 0.67em;
    font-weight: bold;
  }

  a {
    text-decoration: underline;
  }

  ul,
  ol {
    display: block;
    list-style: disc outside none;
    padding: 0 0 0 15px;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    display: list-item;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }

  table {
    display: table;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
    }

    body {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }

    tr {
      border-top: 1px solid #c6cbd1;
      background: #fff;
    }

    th,
    td {
      padding: 5px 5px;
      border: 1px solid #dfe2e5;
    }

    td {
      display: table-cell;
    }
  }

  p img {
    height: 50%;
    width: 50%;
  }
}

.messages__content--system {
  background-color: $messages-system-bg-color;
  color: $gray-500;
  border-radius: $border-radius;
  padding: 20px 50px;
  font-weight: $font-weight-medium;

  p {
    margin: 10px 0;
  }

  ul {
    list-style: disc;
    margin-left: 18px;
  }
}

.messages__img {
  & > img {
    max-width: 360px;
    max-height: 220px;
    min-height: 45px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
}

.reply {
  position: relative;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;

  .emoji-mart {
    @include media-breakpoint-up(md) {
      position: absolute;
      right: 0;
    }
  }
}

.reply__text {
  width: 100%;
  color: $form-input-color;
  resize: none;
  background: $white;
  line-height: 1.25;
  padding: 14px;
  min-height: $reply-base-height;
  max-height: 200px;
  overflow-y: auto;
  display: block;
  border: 1px solid $gray-200;
  border-radius: $border-radius $border-radius 0 0;
  outline: none;
  font-size: $font-size-base;
}

.date-floating {
  .messages__date {
    &:before {
      width: 0;
    }
  }

  &.float-off {
    opacity: 0;
    transition: opacity 0.6s;

    /*Only one element will have this. since the elements are Stacked.*/
    &.float-on {
      opacity: 1;
      transition: opacity 0.6s;
    }
  }
}

.support-tab-content{
  .chat-sidebar-actions{

    border-right: 0;
    border-bottom: 0;
    overflow-y: auto;
    height: 100%;

  }

  .messages__body{
    padding: 0.60rem 0.60rem 0.60rem;
  }

  .issue-title-editor{
    width: calc(100% - 290px);

    @include media-breakpoint-between(xs,sm)  {
      width: 80%;
    }
  }



}
.messages__content {
  .cm-scroller {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
    }
  }
}

.code-mirror-editor{
  .ͼ1.cm-editor.cm-focused{
    outline: 0;
  }
}
