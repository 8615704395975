.tooltip {
  padding: 10px 15px;
  background-color: #3E3E3E;
  color: $white;
  font-size: $font-size-sm;
  border-radius: $border-radius;
}

.__react_component_tooltip {
    padding: 7px 20px;
    
    &.show {
      opacity: 1;
      font-size: $font-size-sm;
    }
    &.show.place-left {
      margin-left: -10px;
      &:before {
        z-index: -1;
      }
    }
    &.show.place-right {
      margin-left: 10px;
      &:before {
        z-index: -1;
      }
    }
    &.show.place-top {
      margin-top: -10px;
      &:before {
        z-index: -1;
      }
    }
    &.show.place-bottom {
      margin-top: 10px;
      &:before {
        z-index: -1;
      }
    }
}

$tooltip-themes: (
  error: $alert-danger-bg,
  "theme-blue": $theme-blue-dark
);

@each $type, $color in $tooltip-themes {
  .tooltip--#{$type} {
    &.__react_component_tooltip {
      background-color: #{$color};

      &.type-dark {
        &.place-top:after {
          border-top-color: #{$color};
        }

        &.place-bottom:after {
          border-bottom-color: #{$color};
        }

        &.place-left:after {
          border-left-color: #{$color};
        }

        &.place-right:after {
          border-right-color: #{$color};
        }
      }
    }
  }
}