.login {
  &:before {
    z-index: -1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    content: "";
    pointer-events: none;
    background: url("../../img/login/login-bg.jpg") no-repeat bottom right -40vw;
    background-size: cover;

    @include media-breakpoint-down(lg) {
      opacity: 0.25;
    }
  }
}

.login-art{
  background: url("../../img/login/login-art.png") no-repeat right -420px top 10px;
  width: 686px;
  height: 684px;
}



