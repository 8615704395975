.failed-check {
  table {
    border-collapse: collapse;
    margin-top: 5px;
    margin-bottom: 25px;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    thead th:empty {
      display: none;
    }

    thead tr {
      color: $white;
      text-align: left;
    }

    th, td {
      padding: 12px 15px;
    }

    tbody tr {
      border-bottom: 1px solid #dddddd;
    }

    tbody tr:nth-of-type(even) {
      background-color: $list-item-border-color;
    }
  }

  p:nth-of-type(1) {
    color: $white;
    font-weight: bold;
    margin-bottom: 5px;
  }

  ul {
    list-style: auto;
    padding-left: 25px;
    padding-bottom: 10px;
  }

  &.sys-os-ntp{
    ul {
      list-style: disc;
    }
  }

  &.error {
    table thead tr {
      background-color: $red;
    }
    p:nth-of-type(1) {
      color: $red;
    }
  }

  &.warning {
    table thead tr {
      background-color: $orange
    }
    p:nth-of-type(1) {
      color: $orange;
    }
  }
}
