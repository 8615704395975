.json-editor {
  .jsoneditor {
    border: 0;
  }

  .jsoneditor-outer {
    margin-left: -38px;
    width: calc(100% + 38px);
    height: calc(100% - 45px);
  }

  .jsoneditor-menu {
    background: $gray-50;
    height: 60px;
    padding: 0 10px;
    border-bottom-color: $gray-100;
    display: flex;
    align-items: center;

    & > button {
      &:not(.jsoneditor-transform) {
        display: none;
      }

      &.jsoneditor-transform {
        background: url(/img/icons/filter-icon.svg) no-repeat center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 34px;
        height: 34px;
        border: 0;

        &:hover {
          background-color: $gray-100;
        }

        &:focus {
          background-color: $gray-200;
          box-shadow: none;
        }
      }
    }
  }

  .jsoneditor-contextmenu-button {
    display: none;
  }

  .jsoneditor-button {
    border-radius: $border-radius;
  }

  .jsoneditor-tree {
    button {

      &.jsoneditor-contextmenu-button{
        display: none;
      }

      &.jsoneditor-button {
        background: url(/img/icons/caret.svg) no-repeat center;
        transform: rotate(-90deg);
      }

      &.jsoneditor-expanded {
        transform: rotate(0deg);
      }
    }
  }

  .jsoneditor-navigation-bar {
    font-family: $font-family;
    background-color: $white;
    border-bottom-color: $gray-100;
    color: $body-color-muted;
    font-size: $font-size-md;
    height: 36px;
    padding: 0 10px;
    line-height: 1;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  div.jsoneditor-field,
  div.jsoneditor-value,
  div.jsoneditor td,
  div.jsoneditor th,
  div.jsoneditor textarea,
  pre.jsoneditor-preview,
  .jsoneditor-schema-error,
  .jsoneditor-popover {
    font-size: $font-size-md;
    background: $gray-50;
  }

  .jsoneditor-treepath .jsoneditor-treepath-element {
    font-family: inherit;
  }

  .jsoneditor-treepath {
    display: flex;
    align-items: center;
  }

  .jsoneditor-treepath-seperator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: url(/img/icons/breadcrumb-sep.svg) no-repeat center right;
    font-size: 0 !important;
    width: 5px;
    height: 10px;
    margin: 3px 5px 0 !important;
  }

  .jsoneditor-frame {
    border: 0;
    background-color: transparent;
    padding: 0;
    position: relative;
    text-align: right;

    input {
      height: 40px !important;
      border: 1px solid $gray-200 !important;
      font-family: $font-family !important;
      padding: 0 35px 1px !important;
      background-size: 13px;
      background: $white url(/img/icons/search.svg) no-repeat left 12px center;
      width: 350px;
      border-radius: $border-radius;
      margin: 0;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:focus {
        border-color: $gray-400 !important;
        box-shadow: 0 0 0 4px $gray-100 !important;
      }
    }
  }

  .jsoneditor-search {
    top: 0;
    right: 0;
    position: relative;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;

    .jsoneditor-refresh {
      display: none;
    }

    button.jsoneditor-next,
    button.jsoneditor-previous {
      background-position: 0 0;
      background-size: 12px;
      height: 100%;
      opacity: 0.5;
      position: absolute;
      top: 0;
      right: 10px;

      &,
      &:hover {
        background: url(/img/icons/caret.svg) no-repeat center;
      }

      &:focus,
      &:hover {
        opacity: 1;
      }
    }

    button.jsoneditor-next {
      transform: rotate(180deg);
      right: 25px;
    }
  }

  .jsoneditor-results {
    font-family: $font-family;
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: $body-color-muted;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &.json-editor--search {
    height: calc(100vh - 220px);
    min-height: 380px;
  }
}

.jsoneditor-modal-overlay {
  background-color: rgba($black, 0.1) !important;
  opacity: 1 !important;
}

.pico-content {
  &.jsoneditor-modal {
    box-shadow: 4px 4px 6px rgba($black, 0.025) !important;
    font-family: $font-family !important;
    padding: 55px !important;
    border-radius: $border-radius !important;
    color: $black;

    .pico-modal-contents {
      a {
        color: $black;
        text-decoration: underline;

        &:focus {
          color: $link-color;
        }
      }

      .jsoneditor-jmespath-label {
        color: $black;
        font-weight: $font-weight-medium;
      }

      textarea {
        padding: 10px 12px !important;
        border-color: $gray-400 !important;

        &:focus {
          box-shadow: 0 0 0 4px $gray-100;
        }
      }

      .pico-modal-header {
        all: unset;
        color: $black ;
        font-weight: $font-weight-medium;
        font-size: $font-size-xl;
        margin-bottom: 25px;
        display: block;
      }
      
      input[type="submit"] {
        all: unset;
        @extend .button;
        border-radius: $border-radius;
        background-color: $theme-blue-dark;
        color: $white;
        font-weight: $font-weight-medium;
        cursor: pointer;

        &:focus {
          box-shadow: 0 0 0 4px $gray-100;
        }
      }

      .jsoneditor-modal-actions {
        all: unset;
        margin: 20px 0 0 0;
        display: block;
        text-align: right;
      }
    }

    .pico-close {
      all: unset;
      width: 30px !important;
      height: 30px !important;
      background: url(/img/icons/close.svg) no-repeat center !important;
      font-size: 0 !important;
      border-radius: 50% !important;
      margin: 15px;
      background-size: 10px !important;

      &:hover,
      &:focus {
        background-color: $gray-100 !important;
      }
    } 
  }
}