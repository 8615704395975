.scrollbar .ps {
  .ps__rail-x:hover,
  .ps__rail-y:hover,
  .ps__rail-x:focus,
  .ps__rail-y:focus,
  .ps__rail-x.ps--clicking,
  .ps__rail-y.ps--clicking {
    background-color: transparent;
  }

  .ps__thumb-y {
    width: 4px;
    border-radius: 3px;
  }

  .ps__rail-y {
    width: 4px;
    opacity: 1 !important;
  }

  .ps__rail-y:hover,
  .ps__rail-y:focus,
  .ps__rail-y.ps--clicking {
    & > .ps__thumb-y {
      width: 4px;
    }
  }
}
