.modal {
  position: fixed;
  left: 0;
  top: 0;
  @include size(100%, 100%);
  z-index: $modal-z-index;
  background-color: $modal-bg;
  display: flex;
  overflow: auto;

  &:not(.modal--sm):not(.modal--md):not(.modal--lg):not(.modal--full):not(.modal--auto):not(.modal--category-modal) {
    .modal__content {
      max-width: $modal-width;
    }
  }

  &:not(.modal--auto) {
    .modal__content {
      width: 100%;
    }
  }
}

.modal--sm {
  .modal__content {
    max-width: $modal-sm-width;
  }
}

.modal--md {
  .modal__content {
    max-width: $modal-md-width;
  }
}

.modal--lg {
  .modal__content {
    max-width: $modal-lg-width;
  }
}

.modal--category-modal{
  .modal__content {
    max-width: $modal-category-width;
  }
}

.modal .modal--confirm-download {
  .modal__icon {
    text-align: left;
    margin: 5px 0 15px;

    & > img {
      height: 44px;
      display: inline-block;
    }
  }

  &.modal__content {
    max-width: 550px;
  }

}


.modal--full{
  .modal__content {
    max-width: 90%;
    height: 90%;
  }
}

.modal__content {
  background-color: $white;
  border-radius: $border-radius-lg;
  position: relative;
  margin: auto;
  box-shadow: 4px 4px 6px rgba($black, 0.025);
}

.modal__title {
  font-weight: $font-weight-medium;
  font-size: $modal-title-font-size;
  margin: -5px 0 20px;
}

.modal__text {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.modal__footer {
  margin-top: 25px;
  text-align: right;

  .button + .button {
    margin-left: 7px;
  }
}

.modal__footer--code {
  display: flex;
  align-items: flex-end;

  .button {
    flex: 0 1 auto;
  }
}

.modal__close {
  @include size(30px, 30px);
  background: $modal-close-icon no-repeat center;
  background-size: 10px;
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  border-radius: 50%;

  &:focus,
  &:hover {
    background-color: $modal-close-hover-bg;
  }
}

.modal--dialog {
  .modal__content {
    padding: 30px;
  }

  .modal__content,
  .modal__footer {
    text-align: center;
  }

  .modal__footer {
    margin-right: -10px;
    margin-left: -10px;
  }
}

.modal__icon {
  text-align: center;
  margin: 5px 0 15px;

  & > img {
    height: 44px;
    display: inline-block;
  }
}

.modal__close_icon {
  @include size(30px, 30px);
  background: $modal-close-icon no-repeat center;
  background-size: 10px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: $modal-close-hover-bg;
  }
}