.updater {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  display: flex;
}

.updater__body {
  background-color: $updater-bg-color;
  margin: 0 auto;
  display: inline-block;
  padding: 5px 15px;
  box-shadow: 1px 2px 2px rgba($black, 0.3);
}
