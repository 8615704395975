$toggle-input-width: 35px;
$toggle-input-width: 35px;
$toggle-input-height: 20px;
$toggle-input-thumb-size: 14px;
$toggle-input-thumb-bg: darken($blue-sky, 10%);

.toggle-input,
.toggle-input__helper {
  width: $toggle-input-width;
  height: $toggle-input-height;
}

.notification-toggle {
  width: 48px;
  height: 22px;

  .toggle-switch-button {
    height: 12px;
    width: 12px;
  }
  &.toggle-switch-on {
    background: #083052;

    .toggle-switch-button {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
  }

  &.toggle-switch-off {
    background: #9aa2af;
    .toggle-switch-button {
      left: 6px;
    }
    .switch-off-label {
      margin-left: 22px;
    }
  }

  .toggle-switch-labels {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    font-size: 11px;
    .switch-off-label {
      padding-left: 7px;
    }
  }
}

.toggle-input {
  position: relative;
}

.toggle-input__checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;

  &:checked {
    & ~ .toggle-input__helper {
      color: $toggle-input-thumb-bg;

      &::before {
        left: calc(100% - #{$toggle-input-thumb-size + 2px});
      }
    }
  }

  &:not(:disabled) {
    cursor: pointer;

    &:focus-visible {
      & ~ .toggle-input__helper {
        box-shadow: 0 0 0 3px $gray-100;
      }
    }

    &:not(:checked) {
      &:hover {
        & ~ .toggle-input__helper {
          color: darken($gray-300, 20%);
        }
      }
    }
  }

  &:disabled {
    pointer-events: none;

    & ~ .toggle-input__helper {
      opacity: 0.5;
    }
  }
}

.toggle-input__helper {
  position: absolute;
  border-radius: 15px;
  border: 1px solid currentColor;
  z-index: 1;
  color: $gray-300;
  transition: color 250ms, border-color 250ms;

  &::before {
    content: "";
    width: $toggle-input-thumb-size;
    height: $toggle-input-thumb-size;
    border-radius: 50%;
    background-color: currentColor;
    left: 2px;
    top: 2px;
    position: absolute;
    transition: left 250ms, background-color 250ms;
  }
}
