.tour-mask {
  color: #1f2750;
  opacity: 0.25;
}

.tour {
  &.reactour__helper {
    box-shadow: none;
    font-weight: $font-weight-medium;
    background: linear-gradient(270deg, #081C42 0%, #073052 100%);
    color: #d4eeff;
    text-align: center;
    font-size: 0.9rem;

    [data-tour-elem="controls"] {
      background: rgba($white, 0.05);
      margin: 20px -30px -25px -30px;
      padding: 0 25px 2px;
      height: 45px;
      display: flex;
      align-items: center;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
    }

    [data-tour-elem="left-arrow"],
    [data-tour-elem="right-arrow"] {
      outline: none;
      width: 45px;
      height: 100%;
      padding-bottom: 1px;

      &:before {
        content: "";
        font-size: 0.8rem;
        color: #67a0c5;
        font-weight: $font-weight-medium;
        line-height: 100%;
      }

      &:not(:disabled) {
        &:hover {
          &:before {
            color: #c4e7ff;
          }
        }
      }

      &:disabled {
        opacity: 0.35;
      }

      & > svg {
        display: none;
      }
    }

    [data-tour-elem="right-arrow"] {
      position: relative;

      &:before {
        content: "Next";
      }
    }

    [data-tour-elem="left-arrow"] {
      &:before {
        content: "Previous";
      }
    }

    [data-tour-elem="dot"] {
      border-radius: 50%;
      height: 4px;
      width: 4px;
      background-color: #3b5d74;
      border: 0;

      &.reactour__dot--is-active {
        background-color: #c4e7ff;
      }
    }
  }
}

.tour__end {
  position: absolute;
  right: -5px;
  padding: 0 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 2px;
  background-color: #15294c;
  color: #93c9ec;
  font-weight: $font-weight-medium;
  height: 30px;
  border: 1px solid #456b84;
  display: flex;
  align-items: center;

  &:hover {
    border-color: #b1dbf6;
  }
}

.__floater {
  filter: none !important;
  transition: none !important;
  margin-left: 12px;
}

.__floater__body {
  margin: -8px;
}

.react-joyride__tooltip {
  button {
    outline: none;

    &:hover {
      border-color: $white !important;
      color: $white !important;
    }
  }
}
