// Layout start
.signup {
  width: 100%;
  @include media-breakpoint-up(lg) {
    background-color: $signup-summary-bg;
  }

  @include media-breakpoint-up(md) {
    padding-left: $signup-aside-width;
    position: relative;
    overflow-y: auto;

    &.resubscribe {
      padding-left: 0;
    }
  }
}

.signup__container {
  width: 100%;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    display: flex;
    max-width: $signup-container-width;

    &:before {
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      width: 40%;
      height: 100%;
      background-color: $white;
      z-index: 0;
    }
  }
}

.signup__main {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: $white;
  position: relative;
  z-index: 1;

  @include media-breakpoint-up(lg) {
    padding: 0 5vw 100px;

    &.resubscribe__main {
      flex: 1;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 0 40px 50px;
    max-width: calc($signup-container-width/2);
    margin: 0 auto;
    width: 100%;
  }

  .plan-features-col{
    min-width: 289px;
    @include media-breakpoint-up(md){
      min-height: 152px;
    }
  }
}
// Layout end

// Aside start
.signup__aside {
  background-color: $signup-aside-bg;

  @include media-breakpoint-up(md) {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: $signup-aside-width;
    z-index: 1;
    padding: 50px 15px;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    padding: 20px 40px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  @include media-breakpoint-down(lg) {
    &.resubscribe__aside {
      display: none;
    }
  }
}
// Aside end

// Summary start
.order {
  background-color: $signup-summary-bg;
  flex-shrink: 0;

  @include media-breakpoint-up(lg) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    align-self: flex-start;
    height: 100vh;
    box-shadow: inset 12px 0 7px -10px rgba($black, 0.05);
    width: 35%;
    min-width: 400px;
  }
}

.order__inner {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include media-breakpoint-up(lg) {
    padding: 60px 60px 20px;
  }

  @include media-breakpoint-down(md) {
    padding: 60px 40px 20px;
    max-width: calc($signup-container-width/2);
    margin: 0 auto;
    width: 100%;
  }
}

.resubscribe__order-details {
  @include media-breakpoint-up(lg) {
    max-height: calc(100vh - 80px);
  }
}
// Summary end

// Loader start
.signup__progress {
  & > i {
    border: 1px solid $signup-progress-active-bg;
    background-color: $signup-progress-bg;
    border-radius: $border-radius-lg;
    position: relative;
    display: block;
    height: 8px;
    width: 100%;

    &:before {
      border-radius: $border-radius;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-color: $signup-progress-active-bg;
      animation: progress 10s ease-in forwards;
      height: 100%;
    }
  }
}

.signup__progress--invite {
  & > i {
    &:before {
      animation: progress 6s ease-in forwards;
    }
  }
}

@keyframes progress {
  0% {
    width: 0;
  }

  20% {
    width: 20%;
  }

  50% {
    width: 70%;
  }

  100% {
    width: 100%;
  }
}
// Loader end

// Steps start
.signup__steps {
  display: flex;
  z-index: 3;
  list-style: none;
  align-items: center;
  background-color: $white;
  position: sticky;
  top: 0;
  margin: 0 -10px;

  @include media-breakpoint-up(md) {
    padding: 60px 15px 70px 15px;
  }

  @include media-breakpoint-down(sm) {
    padding: 50px 15px 60px;
  }

  & > li {
    height: 2px;
    background-color: $signup-steps-line-bg;
    position: relative;

    &:not(:last-child) {
      flex: 1 0;
    }

    &:last-child {
      flex: 0;
      width: 0;
      margin-right: 15px;
    }

    &.active {
      &:before {
        background-color: $black;
      }

      & > span {
        color: $black;
      }
    }

    &.completed {
      &,
      &:before {
        background-color: $signup-steps-complete-bg;
      }

      & > span {
        color: $signup-steps-complete-color;
      }
    }

    &:before {
      content: "";
      @include size(10px, 10px);
      background-color: $signup-steps-line-bg;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border-radius: 50%;
      box-shadow: 0 0 0 5px $white;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        @include size(8px, 8px);
      }
    }

    & > span {
      font-weight: $font-weight-medium;
      color: rgba($black, 0.25);
      position: absolute;
      left: 7px;
      top: 17px;
      transform: translateX(-50%);
    }
  }
}
// Steps end

.choose-plan-dropdown { //FIXME: use common select styling as per DS
  .form-field {
    border: 1px solid $form-input-focus-border-color;
  }
  .dropdown:not(.dropdown--up) .dropdown__options {
    border-top: 0;
  }

  .dropdown__options {
    border: 1px solid $form-input-focus-border-color;
  }

  &.dropdown {
    &:not(.dropdown--up) {
      .dropdown__options {
        margin-top: -1px;
        top: 100%;
        border-top-color: $form-input-focus-border-color;
      }
    }
  }

  &.dropdown--active {
    border-bottom: 0;
    border-radius: 5px 5px 0 0;
  }

  .dropdown--active {
    .form-field {
      border: none;
      background-color: transparent;
    }
    .dropdown__options {
      display: block;
      margin-top: 1px;
    }
  }

  .dropdown-option:not(:last-child) {
    border-bottom: 1px solid $form-input-focus-border-color;
  }
}
