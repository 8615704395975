#ie {
  position: fixed;
  left: 0;
  top: 0;
  @include size(100%, 100%);
  z-index: $ie-z-index;
  background-color: $ie-bg;
  font-family: $ie-font-family;
}

.ie__inner {
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 50px;
}

.ie__message {
  font-size: $font-size-lg;
}

.ie__browsers {
  margin: 40px 0;

  & > a {
    display: inline; //IE 8 and below
    display: inline-block;
    padding: 10px 15px;
    border-radius: $border-radius-lg;

    &:hover {
      background-color: $ie-browser-hover-bg;
    }

    & > span {
      margin-top: 5px;
      display: block;
      line-height: 100%;
      color: $ie-browser-color;
    }
  }
}

#ie__skip {
  font-size: $font-size-sm;
  color: $ie-skip-color;

  &:hover {
    color: $ie-browser-color;
  }
}

