.rta {
  position: relative;
  font-size: $font-size-base + 2;
}

.rta__autocomplete {
  position: absolute;
  z-index: 16;

  @include media-breakpoint-down(sm) {
    left: 0 !important;
    bottom: $reply-base-height - 10;
    top: auto !important;
  }
}

.rta__autocomplete--top {
  margin-top: 0;
  margin-bottom: 10px;
}

.rta__list {
  margin: 0;
  padding: 5px;
  background: $dropdown-options-bg;
  border: 1px solid $dropdown-options-border-color;
  border-radius: $border-radius;
  list-style: none;
  max-height: 250px;
  overflow-y: auto;
}

.rta__item {
  padding: 6px 8px;
  border-radius: $border-radius;
  color: $dropdown-item-color;
  cursor: pointer;
}

.rta__item--selected {
  background-color: $dropdown-auto-suggest-bg;
  border-radius:0;
}
