// Loader
.loading {
  @include loader(35px, $theme-muted, $theme-blue-light);
}

.loading-xs {
  @include loader(25px, $theme-muted, $theme-blue-light);
}


// Backdrop
.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  @include size(100%, 100%);
  cursor: pointer;
}

// ul-li
.ul-bullet {
  & > li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    &:before {
      content: "";
      margin: 7px 15px 0 0;
      width: 5px;
      height: 5px;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: currentColor;
    }
  }
}

// Print
@media print {
  .none-print {
    display: none !important;
  }
}

// Password guidelines
.password-guideline {
  padding-left: 20px;

  &:before,
  &:after {
    content: "";
    margin: auto 0;
    position: absolute;
    bottom: 0;
    transition: transform 400ms;
    top: 3px;
  }

  &:before {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: currentColor;
    left: 0;
  }

  &:after {
    left: -2px;
    width: 10px;
    height: 5px;
    background-color: transparent;
    border-bottom: 1px solid $green;
    border-left: 1px solid $green;
    border-radius: 0;
    transform: rotate(180deg) scale(0);
  }

  &.valid {
    &:before {
      transform: scale(0);
    }

    &:after {
      transform: rotate(-45deg) scale(1);
    }
  }
}

// Caret
.caret {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: $black transparent transparent transparent;
}

.plan-feature-stripe-row {
  background: #f7f7f7;
}

.hover-trigger .hover-target {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.hover-trigger:hover .hover-target {
  display: block;
}

// Clipboard button icon
.clipboard-button {
  width: 17px;
  height: 19px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;

  &:not(.clipboard-button--light) {
    background-image: url(../../img/icons/copy-inverse-inactive.svg);

    &.clipboard-button--active,
    &:hover {
      background-image: url(../../img/icons/copy-inverse-active.svg);
    }
  } 
}

.clipboard-button--light {
  background-image: url(../../img/icons/copy-inactive.svg);

  &.clipboard-button--active,
  &:hover {
    background-image: url(../../img/icons/copy-active.svg);
  }
}

// OTP
.otp {
  &.otp--invalid {
    .otp__helper {
      & > i {
        border-color: $red;
        color: $red;
      }
    }

    .otp__code {
      color: $red;
    }

    .otp__input {
      animation: shake 700ms cubic-bezier(0.36,0.07,0.19,0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }

  &:not(.otp--invalid) {
    .otp__helper {
      & > i {
        &.active {
          border-color: #667C94;
        }
      }
    }
  }
}

.otp__input {
  position: relative;
  width: 272px;
  height: 45px;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0.25rem;

  @include media-breakpoint-down(sm) {
    transform: scale(0.9);
  }
}

.otp__text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  font-size: 5px;
  z-index: 2;

  &:focus {
    & ~ .otp__helper {
      & > i {
        border-color: darken($gray-300, 10%);
      }
    }

    & ~ .otp__code {
      &:after {
        animation: blinking 650ms infinite;
      }
    }
  }
}

.otp__code {
  position: absolute;
  top: 0;
  left: 0;
  font-family: $font-family-mono;
  font-size: 1.5rem;
  letter-spacing: 2.4rem;
  padding: 2px 0 0 14px;
  line-height: 1;
  pointer-events: none;
  height: 100%;
  transition: color 300ms;
  line-height: 100%;
  display: flex;
  align-items: center;

  &:after {
    content: "";
    width: 1px;
    height: 25px;
    background-color: transparent;
    position: absolute;
    right: -8px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.otp__helper {
  display: flex;
  justify-content: space-between;

  & > i {
    width: 40px;
    height: 45px;
    border: 2px solid $gray-200;
    border-radius: $border-radius;
    transition: border-color 300ms;
    flex-shrink: 0;
  }
}

@keyframes blinking {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: $gray-400;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

// Custom scrollbar
.scrollbar {
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;

  &:hover {
    scrollbar-color: $gray-100 transparent;

    &::-webkit-scrollbar-thumb {
      background-color: $gray-100;
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }
  
  &::-webkit-scrollbar-track {
    background-color: rgba($black, 0);
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 1rem;
    border: 3px solid transparent;
    background-clip: content-box;

    &:hover {
      background-color: $gray-200;
    }
  }
}

.overlay-container{

  &:hover{
    .overlay-actions{
      right: 0;
      display: block;
    }
  }

  @include media-breakpoint-between(xs,sm)  {
    &:focus-within{
      .overlay-actions{
        right: 0;
        display: block;
      }
    }
  }
  .overlay-actions{
    right: 0;
    position: absolute;
    top: 0;  bottom: 0;
    display: none;
    z-index: 15;
  }
}

.participation-arrow-indicator{
  width: 7px;
  height: 7px;
  border: 1px solid $gray-100;
  border-left: 0;
  border-top: 45px;
  transform: rotate(225deg);
  position: relative;
  top: -112px;
  background: #fbfcfc;
  left: 42px;
}


.cluster-profile-type{
  &:hover,&:focus{
  .profile-type-icon{
        background: $white;
      }
  }
}
.eng-activity-details {
  .react-horizontal-scrolling-menu--wrapper {
    .react-horizontal-scrolling-menu--inner-wrapper {
      border: none;
    }
  }
}