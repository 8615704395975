.alert {
  border: 0;
  position: fixed;
  width: 100%;
  color: $white;
  padding: 0 50px 0 15px;
  z-index: $alert-z-index;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-medium;

  &:not(.alert--alt) {
    left: 0;
    right: 0;
    top: 0;
    height: $alert-height;

    @include media-breakpoint-between(xs,md)  {
      height: auto;
      min-height: 75px;
      max-height: 80vh;
    }
  }
}

.alert--success {
  background-color: $alert-success-bg;
}

.alert--danger {
  background-color: $alert-danger-bg;
}

.alert--info {
  background-color: $alert-info-bg;
}

.alert__action {
  @include size(29px, 29px);
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  background: $alert-close-icon;

  &:hover,
  &:focus {
    background-color: rgba($white, 0.2);
  }
}

.progress {
  height: 3px;
  margin: 5px 0;
  background-color: darken($blue, 10%);
  z-index: $upload-bottom-z-index;
  border-radius: $border-radius;
  overflow-y: hidden;
}

.progress__bar {
  height: 100%;
  @include size(0, 100%);
  background-color: $white;
  z-index: $upload-top-z-index;
}

.upload-files-list {
  bottom: 6rem;
  max-height: 300px;
  overflow: auto;
}

.download-close-btn {
  @include size(29px, 29px);
  min-width: 29px;
  cursor: pointer;
  border-radius: 50%;
  background: $alert-close-icon;

  &:hover {
    background-color: rgba($white, 0.2);
  }
}
