.panic {
  background-color: $panic-bg;
}

.panic__map {
  pointer-events: none;
  left: 0;
  top: 0;
  position: absolute;
  @include size(100%, 100%);
  z-index: 1;

  .jvectormap-container {
    height: 100%;
    padding: 30px;
  }

  .jvectormap-marker {
    animation: ip-ring 2s linear infinite;
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation-fill-mode: both;
  }
}

.panic__icon {
  box-shadow: 0 0 0 rgba($panic-theme, 0.75);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($panic-theme, 0.75);
  }
  70% {
    box-shadow: 0 0 0 15px rgba($panic-theme, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($panic-theme, 0);
  }
}

@keyframes ip-ring {
  from {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0);
  }

  to {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
}
