// Size
@mixin size($width, $height) {
  width: $width;
  height: $height;
}


// Button Variants
@mixin button-variants($bg) {
  background: $bg;
}


// Text Truncate
@mixin text-truncate() {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// Text Crop
@mixin text-crop($line-height: 1.15, $top-adjustment: 0px, $bottom-adjustment: 0px) {
  $top-crop: 9;
  $bottom-crop: 3;
  $crop-font-size: 36;
  $crop-line-height: 1.2;

  $dynamic-top-crop: max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;
  $dynamic-bottom-crop: max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;

  line-height: $line-height;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
  }

  &::after {
    margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
  }
}


// User select
@mixin user-select($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}


// Appearance
@mixin appearance($value: none) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}


// Centarize
@mixin make-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}


// Placeholder
@mixin placeholder {
  &::-webkit-input-placeholder { @content }
  &:-moz-placeholder { @content }
  &::-moz-placeholder { @content }
  &:-ms-input-placeholder { @content }
}


// Breakpoints
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}


// Loader
@mixin loader($size, $track-bg, $point-bg) {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-top: 1px solid $track-bg;
  border-right: 1px solid $track-bg;
  border-bottom: 1px solid $track-bg;
  border-left: 1px solid $point-bg;
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;
  border-radius: 50%;
  @include size($size, $size);
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
