@tailwind base;
@tailwind components;
@tailwind utilities;

// Core
@import "includes/functions";
@import "includes/variables";
@import "includes/mixins";

@layer base {
  @import "includes/fonts";
  @import "includes/base";
}

@layer components {
  @import "includes/misc";
  @import "includes/buttons";
  @import "includes/forms";
  @import "includes/layout";
  @import "includes/alert";
  @import "includes/sidebar";
  @import "includes/header";
  @import "includes/issues";
  @import "includes/messages";
  @import "includes/login";
  @import "includes/dropdown";
  @import "includes/tooltips";
  @import "includes/modal";
  @import "includes/profile";
  @import "includes/editor";
  @import "includes/list";
  @import "includes/updater";
  @import "includes/ie-warning";
  @import "includes/search";
  @import "includes/panic";
  @import "includes/signup";
  @import "includes/invite";
  @import "includes/billing";
  @import "includes/health";
  @import "includes/failed-check";
  @import "includes/toggle-input";
  @import "includes/system-alert";
}

// Vendors
@import "includes/vendors/phone-input";
@import "includes/vendors/rta";
@import "includes/vendors/perfect-scrollbars";
@import "includes/vendors/react-select";
@import "includes/vendors/chatlio";
@import "includes/vendors/reactour";
@import "includes/vendors/datepicker";
@import "includes/vendors/emoji-mart";
@import "includes/vendors/json-editor";
@import "includes/vendors/scrolling-tabs";
@import "includes/vendors/jvectormap";
