.system-alert {
  border: 0;
  width: 100%;
  color: $white;
  padding: 0 50px 0 15px;
  z-index: $system-alert-z-index;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-medium;

  &:not(.alert--alt) {
    left: 0;
    right: 0;
    top: 0;
    height: $alert-height;

    @include media-breakpoint-between(xs,md)  {
      height: auto;
      min-height: 75px;
      max-height: 80vh;
    }
  }
}

.system-alert--success {
  background-color: $alert-success-bg;
}

.system-alert--danger {
  background-color: $alert-danger-bg;
}

.system-alert--info {
  background-color: $alert-info-bg;
}