.emoji-mart {
  font-family: inherit;
  border-color: $dropdown-options-border-color;
  border-radius: $border-radius !important;
  z-index: 20;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
    border-radius: 0;
    border-width: 1px;
  }

  em-emoji-picker {
    --border-radius: $border-radius;
  }

  .emoji-mart-scroll {
    background: $white;

    @include media-breakpoint-down(sm) {
      height: 150px;
    }
  }

  .emoji-mart-bar {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .emoji-mart-search,
  .emoji-mart-scroll {
    background: $white;
  }

  .emoji-mart-search,
  .emoji-mart-scroll,
  .emoji-mart-bar {
    border-bottom: 0;
  }

  .emoji-mart-search {
    margin: 0;
    border-top: 0;
    padding: 0;

    input {
      border-radius: 0;
      padding: 0 10px;
      height: 32px;
      font-size: $font-size-sm;
      border-right: 0;
      border-left: 0;

      @include media-breakpoint-down(sm) {
        border-top: 0;
      }
    }
  }

  .emoji-mart-scroll {
    border-top: 0;
  }

  .emoji-mart-title-label {
    font-size: $font-size-base;
  }

  .emoji-mart-category-label {
    span {
      font-size: $font-size-sm;
      padding: 10px 5px;
    }
  }

  .emoji-mart-preview {
    height: 50px;
  }

  .emoji-mart-preview-name {
    display: none;
  }

  .emoji-mart-preview-emoji {
    left: 0;
  }

  .emoji-mart-anchors {
    svg,
    img {
      margin: 0 auto;
    }
  }
}
