input {
  &:focus-visible,
  &:focus {
    outline: 0;
  }
}

input:not(.form-field) {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: none;
  -moz-appearance: textfield;
}
/* Radio styles */

label > input[type="radio"] {
  display: none;
}
label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: $blue;
}
label > input[type="radio"]:checked + * {
  color: $blue;
}
label > input[type="radio"]:checked + span::before {
  background: radial-gradient($blue 0%, $blue 40%, transparent 50%, transparent);
  border-color: $blue;
  margin-top: 2px;
}



textarea {
  resize: none !important; //FIXME
}

.form {
  width: 100%;
  min-width: 0;
  position: relative;
}

.form__group {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

// BEM is no longer in user. Need to remove/replace existing classes
select.form-field {
  @include appearance(none);
  padding-right: 25px;
  background: $form-input-bg $select-caret no-repeat right 10px center;
}

%form-disabled {
  color: $gray-400;
  border-color: $gray-300;
  background-color: $gray-50;
}

.form-field {
  height: $form-input-height;
  font-size: $font-size-sm;
  background-color: $form-input-bg;
  width: 100%;
  border-radius: $border-radius;
  border: 1px solid $form-input-border-color;
  padding: 0 1rem;
  position: relative;
  box-shadow: none;
  color: $form-input-color;
  appearance: none;
  resize: none;

  &:focus {
    border-color: $form-input-focus-border-color;
    box-shadow: 0 0 0 4px $gray-100;
    z-index: 1;
  }

  &:disabled {
    @extend %form-disabled;
  }

  @include placeholder {
    color: $placeholder-color;
    font-size: $font-size-sm;
  }

  &.invalid {
    border-color: $red !important;
  }
}

div.form-field {
  background-color: $white !important;
  border-color: $form-input-border-color !important;
}

textarea.form-field {
  padding-top: 10px;
}

*:not(select) {
  &.form-field {
    &:read-only {
      @extend %form-disabled;
    }
  }
}

.form-label {
  color: $black;
  font-weight: $font-weight-medium;
  display: block;
  margin-bottom: 0.5rem;
}

.form-error {
  font-size: $font-size-sm;
  margin-top: 0.25rem;
  color: $red;
}

.scrollbar-textarea {
  .scrollbar-container {
    height: 100px;
  }
}

.license-element{
  max-width: 270px;
}

$toggle-input-height:22px;

.toggle-switch {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  height: 22px;
  background: #fff;
  position: relative;
  border-radius: 35px;
  transition: background-color 0.2s;

  .toggle-switch-button {
    position: absolute;
    z-index: 1;
    top: 5px;
    width: 17px;
    height: 17px;
    border-radius: 18px;
    transition: 0.2s;
    background: $white;
  }

  &.toggle-switch-on{
    background: $green;

    .toggle-switch-button {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
  }

  &.toggle-switch-off{
    background: $orange;
    .toggle-switch-button {
      left: 6px;
    }
  }



  .toggle-switch-labels{
    display: flex;
    color:$white;
    div{
      flex:1;
      display:flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;

      &.switch-off-label{
        justify-content: left;
        margin-top: 1px;
        margin-left: 4px;
      }
    }
  }

  .switch-off-label{
    margin-left: 18px;

  }

  .switch-on-label{
    margin-right: 18px;
  }

   .toggle-switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
  }

   .toggle-switch-labels {
    z-index: 0;
    height: 100%;
  }

}

.left-add-on{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;

  :focus{
    border-left-width: 1px;
  }
}

.dropdown {
  .follow-menu {
    .autocomplete__inner__control {
      height: 28px
    }
  }
  .todo-select {
    .autocomplete__inner__control {
      height: 40px
    }
  }
}