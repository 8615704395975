.header {
  background-color: $header-bg;
  flex: 0 0 $header-height;
  box-shadow: 0 3px 10px rgba($black, 0.075);
  position: relative;
  z-index: $header-z-index;
  display: flex;
  align-items: center;
}

.op-popup {
  &:before,
  &:after {
    content: "";
    @include size(0, 0);
    border-style: solid;
    position: absolute;
  }

  &:before {
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $op-popup-border-color transparent;
    top: -10px;
    left: 13px;
  }

  &:after {
    border-width: 0 8px 9px 8px;
    border-color: transparent transparent $white transparent;
    top: -9px;
    left: 15px;
    z-index: 1;
  }
}

.op-popup--top {
  &:before {
    border-width: 10px 10px 0 10px;
    border-color: $op-popup-border-color transparent transparent transparent;
    top: auto;
    bottom: -10px;
  }

  &:after {
    border-width: 9px 8px 0 8px;
    border-color: $white transparent transparent transparent;
    top: auto;
    bottom: -9px;
  }
}

.sidebar-toggle {
  @include size(40px, 40px);
  flex-shrink: 0;
  background: $theme-blue-light url(../../img/icons/menu.svg) no-repeat center;
  margin-right: 15px;
  border-radius: $border-radius;
  cursor: pointer;

  &:hover {
    background-color: $theme-blue-dark;
  }
}
